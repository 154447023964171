import { createSlice } from "@reduxjs/toolkit";

export const questionSearchSlice = createSlice({
  name: 'questionSearchValue',
  initialState: '',
  reducers: {
    setQuestionSearchValue: (state, action) => {
      return action.payload;
    },
    clearQuestionSearchValue: (state) => {
      return '';
    },
  },
});

export const { setQuestionSearchValue, clearQuestionSearchValue } = questionSearchSlice.actions;
export const questionSearchSelector = (state) => state.questionSearch;
export default questionSearchSlice.reducer;