export const sortByStarred = (flows) => {
  if (!Array.isArray(flows)) return [];
  const sortedFlows = flows && flows?.sort((a,b) => {
    if (a.starred === true && b.starred === false) {
      return -1; //place a above b
    } else if (a.starred === false && b.starred === true) {
      return 1;
    } else return 0;
  })
  return sortedFlows;
}