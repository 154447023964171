import { Flex } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

const FallbackUI = () => {
  return (
    <Flex justifyContent="center" alignItems="center" w="100%" h="100vh">
      <Spinner
        mx="auto"
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blueGreen.300"
        size="xl"
      />
    </Flex>
  );
};

export default FallbackUI;
