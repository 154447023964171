/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  let textColor = useColorModeValue("secondaryGreen.200", "white");
//   let linkColor = useColorModeValue({ base: "gray.400", lg: "white" }, "white");
let linkColor = useColorModeValue('secondaryGreen.100', "white");

  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px={{ base: "30px", md: "0px" }}
      pb='30px'>
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        w="48%"
        mb={{ base: "20px", lg: "0px" }}>
        <Text as='span' fontWeight='500' ms='4px' w="100%">
          By signing in or creating an account, you agree with our
          <Link
            mx='3px'
            color={linkColor}
            href='https://www.simmmple.com?ref=horizon-chakra-free'
            target='_blank'>
            Terms & conditions
          </Link>
        </Text>
        <Text fontWeight='500' ms='4px' as="span">
            and
            <Link
            mx='3px'
            color={linkColor}
            href='https://www.simmmple.com?ref=horizon-chakra-free'
            target='_blank'>
            Privacy Statement
          </Link>
        </Text>
      </Text>
    </Flex>
  );
}
