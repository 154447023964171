import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/apiEndPoints";
import { getRequest } from "screens/utils/axiosUtils";
// thunk

export const fetchUserMessages = createAsyncThunk(
  "userMessages/fetchUserMessages",
  async (userId, thunkAPI) => {
    const userMessages = await getRequest(`${api().users}/${userId}/messages`);
    if (userMessages) return userMessages;
    return [];
  }
);

// Then, handle actions in your reducers:
export const userMessagesSlice = createSlice({
  name: "userMessagesState",
  initialState: { messages: [], loading: "idle"},
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as
    builder
      .addCase(fetchUserMessages.pending, (state) => {
        // Set loading state to 'loading' when the request is in progress
        state.loading = "loading";
      })
      .addCase(fetchUserMessages.fulfilled, (state, action) => {
        // Add user to the state array
        state.loading = "idle";
        state.messages = action.payload;
      })
      .addCase(fetchUserMessages.rejected, (state) => {
        // Set loading state back to 'idle' when the request is rejected
        state.loading = "error";
      });
  },
});

export const userMessagesSelector = (state) =>
  state.userMessages.messages;
export const loadingSelector = (state) => state.userMessages.loading;
export default userMessagesSlice.reducer;
