import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../api/apiEndPoints';
import { getRequest } from 'screens/utils/axiosUtils';
// thunk

export const fetchBooking = createAsyncThunk(
  'bookings/fetchBooking',
  async (bookingId, thunkAPI) => {
    const bookingById = await getRequest(`${api().bookings}/${bookingId}`);
    if (bookingById) return bookingById;
    return [];
  }
)

// Then, handle actions in your reducers:
export const bookingSlice = createSlice({
  name: 'bookingState',
  initialState: { booking: {}, loading: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as
    builder
    .addCase(fetchBooking.pending, (state) => {
        // Set loading state to 'loading' when the request is in progress
        state.loading = 'loading';
      })
    .addCase(fetchBooking.fulfilled, (state, action) => {
      // Add user to the state array
      state.loading = 'idle';
      state.booking = action.payload
    })
    .addCase(fetchBooking.rejected, (state) => {
        // Set loading state back to 'idle' when the request is rejected
        state.loading = 'error';
      })
  },
})

export const bookingSelector = (state) => state.booking.booking;
export const loadingSelector = (state) => state.booking.loading;
export default bookingSlice.reducer;
