import { toast } from "react-toastify";
// import { MdCheckCircle } from "react-icons/md";
import { MdCheckCircle } from "screens/components/icons/Icons";
import { Icon } from "@chakra-ui/react";
import "style.css";
import { MdError } from "react-icons/md";

export const showSuccessToast = (message, id) => {
  if (id) {
    toast.success(message, {
      icon: <Icon as={MdCheckCircle} boxSize={25} color="#55c3bf" />,
      progressClassName: "success-progress-bar",
      toastId: id,
    });
  } else {
    toast.success(message, {
      icon: <Icon as={MdCheckCircle} boxSize={25} color="#55c3bf" />,
      progressClassName: "success-progress-bar",
    });
  }
};

export const showErrorToast = (message, id) => {
  if (id) {
    toast.error(message, {
      icon: <Icon as={MdError} boxSize={25} color="#f56565" />,
      progressClassName: "error-progress-bar",
      toastId: id,
    });
  } else {
    toast.error(message, {
      icon: <Icon as={MdError} boxSize={25} color="#f56565" />,
      progressClassName: "error-progress-bar",
    });
  }
};
