import { api } from "api/apiEndPoints";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Box, Text, Button, Flex } = require("@chakra-ui/react");

const RefreshSession = () => {
    const history = useHistory();
  // const refreshToken =
  const refreshSession = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const config = {
        headers: {
          Authorization: `Bearer ${refreshToken}`,  
        },
      };
    const response = await axios.post(api().refreshSession, {}, config)
    localStorage.setItem('token', response.data.access_token);
    history.replace('/')
  };

  return (
    <Box mx="auto">
      <Text p={5} mb={10} align="center">You session has expired</Text>
      <Flex justifyContent="center">
        <Button mx="auto" onClick={refreshSession}>
          Login to continue your session
        </Button>
      </Flex>
    </Box>
  );
};

export default RefreshSession;
