import { getAccessToken } from "./localStorageUtil";
import axios from "axios";
import axiosRetry from "axios-retry";

// Create an axios instance
const axiosInstance = axios.create();

// Apply the retry logic to the axios instance
axiosRetry(axiosInstance, {
  retries: 3, // Number of retries
  retryCondition: (error) => {
    // Retry on network errors or 5xx responses
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response?.status === 502;
  },
});
export const deleteRequest = async (url) => {
  try {
    const accessToken = getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.delete(url, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteRequestWithParams = async (url, params) => {
  try {
    const accessToken = getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: params,
    };
    const response = await axios.delete(url, config);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const postRequest = async (url, body = {}) => {
  try {
    const accessToken = getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, body, config);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const putRequest = async (url, body) => {
  try {
    const accessToken = getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.put(url, body, config);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getRequest = async (url) => {
  try {
    const accessToken = getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (err) {
    console.log(err);
    return err
  }
};

export const getRequestWithParams = async (url, params) => {
  try {
    const accessToken = getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: params,
    };
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
