import React from "react";
import { withRouter } from "react-router-dom";
import { getAccessToken } from "screens/utils/localStorageUtil";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
    const location = useLocation();
    const pathname = location.pathname;

  useEffect(() => {
    const handleRouteChange = () => {
      const token = getAccessToken();

      if (pathname.includes('refresh-token')) return;

      if (token) {
        const decodedJwt = parseJwt(token);

        if (decodedJwt.exp * 1000 < Date.now()) {
          localStorage.removeItem('token');
          props.history.replace('/refresh-token');
        }
      }
    };

    // Listen to route changes
    const cleanup = props.history.listen(handleRouteChange);

    // No cleanup function needed if you want to keep the listener active
    return () => {
      cleanup();
    }
  }, [pathname, props.history]);

  return <div></div>;
};

export default withRouter(AuthVerify);