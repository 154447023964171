export const storeItem = (key, value) => {
    localStorage.setItem(key, value);
}

export const getCompanyId = () => {
    return localStorage.getItem('company_id');
}

export const getLoggedInUserId = () => {
   return localStorage.getItem('user_id');
}
// add loggedinuser info in the function below as well later on
export const getUserCredentials = () => {
    const userCredentials = {
        email: localStorage.getItem('user_email') || '',
        role: localStorage.getItem('role') || '',
        phoneNumber: localStorage.getItem('phone') || '',
    };
    return userCredentials;
}

export const getAccessToken = () => {
    return localStorage.getItem('token');
}

export const getCompanyLanguage = () => {
    return localStorage.getItem('company_language');
}

export const getUserFirstName = () => {
    return localStorage.getItem('firstName') || '';
}