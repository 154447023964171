import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/apiEndPoints";
import { getRequest } from "screens/utils/axiosUtils";
// thunk

export const fetchBookingMessages = createAsyncThunk(
  "bookings/fetchBookingMessages",
  async (bookingId, thunkAPI) => {
    const messagesPerBooking = await getRequest(`${api().bookings}/${bookingId}/messages`);
    if (messagesPerBooking) return messagesPerBooking;
    return [];
  }
);

// Then, handle actions in your reducers:
export const bookingMessagesSlice = createSlice({
  name: "bookingMessagesState",
  initialState: { bookingMessages: [], loading: "idle" },
  reducers: {
    clearMessages: (state) => {
      state.bookingMessages = [];
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as
    builder
      .addCase(fetchBookingMessages.pending, (state) => {
        // Set loading state to 'loading' when the request is in progress
        state.loading = "loading";
      })
      .addCase(fetchBookingMessages.fulfilled, (state, action) => {
        // Add user to the state array
        state.loading = "idle";
        state.bookingMessages = action.payload;
      })
      .addCase(fetchBookingMessages.rejected, (state) => {
        // Set loading state back to 'idle' when the request is rejected
        state.loading = "error";
      });
  },
});

export const bookingMessagesSelector = (state) =>
  state.bookingMessages.bookingMessages;
export const loadingSelector = (state) => state.bookingMessages.loading;
export const { clearMessages } = bookingMessagesSlice.actions;

export default bookingMessagesSlice.reducer;
