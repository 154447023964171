export const radioStyles = {
  components: {
    Radio: {
      container: {
        baseStyle: {
            border: "1px solid black",
        }
      },
    },
  },
};
