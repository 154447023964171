import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const activeSubmenuSlice = createSlice({
  name: "activeSubmenu",
  initialState,
  reducers: {
    setActiveSubmenu: (state, action) => {
      return action.payload;
    }
  }
});

export const { setActiveSubmenu } = activeSubmenuSlice.actions;

export const selectActiveSubmenu = (state) => state.activeSubmenu;

export default activeSubmenuSlice.reducer;
