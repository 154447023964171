import { createSlice } from "@reduxjs/toolkit";

export const actionSearchSlice = createSlice({
  name: 'actionSearchValue',
  initialState: '',
  reducers: {
    setActionSearchValue: (state, action) => {
      return action.payload;
    },
    clearActionSearchValue: (state) => {
      return '';
    },
  },
});

export const { setActionSearchValue, clearActionSearchValue } = actionSearchSlice.actions;
export const actionSearchSelector = (state) => state.actionSearch;
export default actionSearchSlice.reducer;