export const sortItems = (items) => {
  const sortedItems = items.sort((a, b) => {
    const createdAtA = new Date(a.created_at).toISOString();
    const createdAtB = new Date(b.created_at).toISOString();
    if (a.status === "PENDING" && b.status === "DONE") {
      return -1; // Place a above b
    } else if (a.status === "DONE" && b.status === "PENDING") {
      return 1; // Place b above a
    } else {
      // Both objects have the same status, compare received times
      if (createdAtA < createdAtB) {
        return 1; // Place a above b
      } else if (createdAtA > createdAtB) {
        return -1; // Place b above a
      } else {
        return 0; // Equal received times, keep order as is
      }
    }
  });
  return sortedItems;
};
