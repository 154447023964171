import { createSlice } from "@reduxjs/toolkit";

export const chatSearchSlice = createSlice({
  name: 'chatSearchValue',
  initialState: '',
  reducers: {
    setChatSearchValue: (state, action) => {
      return action.payload;
    },
    clearChatSearchValue: (state) => {
      return '';
    },
  },
});

export const { setChatSearchValue, clearChatSearchValue } = chatSearchSlice.actions;
export const chatSearchSelector = (state) => state.chatSearch;
export default chatSearchSlice.reducer;