import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../api/apiEndPoints';
import { getRequest } from 'screens/utils/axiosUtils';
// thunk

export const fetchBookingActions = createAsyncThunk(
  'bookingActions/fetchBookingActions',
  async (bookingId, thunkAPI) => {
    const actionsPerBooking = await getRequest(`${api().bookings}/${bookingId}/pending_actions`);
    if (actionsPerBooking) return actionsPerBooking;
    return [];
  }
)

// Then, handle actions in your reducers:
export const bookingActionsSlice = createSlice({
  name: 'userBookingsState',
  initialState: { bookingActions: [], loading: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as
    builder
    .addCase(fetchBookingActions.pending, (state) => {
        // Set loading state to 'loading' when the request is in progress
        state.loading = 'loading';
      })
    .addCase(fetchBookingActions.fulfilled, (state, action) => {
      // Add user to the state array
      state.loading = 'idle';
      state.bookingActions = action.payload
    })
    .addCase(fetchBookingActions.rejected, (state) => {
        // Set loading state back to 'idle' when the request is rejected
        state.loading = 'error';
      })
  },
})

export const bookingActionsSelector = (state) => state.bookingActions.bookingActions;
export const loadingSelector = (state) => state.bookingActions.loading;
export default bookingActionsSlice.reducer;
 