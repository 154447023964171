import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/apiEndPoints";
import { getRequest, postRequest, putRequest } from "../../../screens/utils/axiosUtils";
import { getCompanyId } from "../../../screens/utils/localStorageUtil";

export const fetchCompanySettings = createAsyncThunk(
    'companySettings/fetchCompanySettings',
    async (_, thunkAPI) => {
        try {
            const url = `${api().companySettings}`;
            const companySettings = await getRequest(url);
            if (companySettings) return companySettings;
            return thunkAPI.rejectWithValue('No company settings found');
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || 'API Error');
        }
    }
);

export const saveCompanySetting = createAsyncThunk(
    'companySettings/saveCompanySetting',
    async (settings, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const existingSettings = state.companySettings.data;
            const companyId = getCompanyId();
            const baseUrl = `${api().companySettings}`;

            const settingsArray = Array.isArray(settings)
                ? settings.map(item => {
                    const [name, value] = Object.entries(item)[0];
                    return { name, value };
                })
                : Object.entries(settings).map(([name, value]) => ({ name, value }));

            const promises = settingsArray.map(({ name, value }) => {
                const existingSetting = existingSettings.find(setting => setting.name === name);
                if (existingSetting) {
                    return putRequest(`${baseUrl}/${existingSetting.id}`, { name, value })
                        .then(response => ({
                            type: 'update',
                            id: existingSetting.id,
                            name,
                            value,
                            response
                        }));
                } else {
                    return postRequest(baseUrl, { company_id: companyId, name, value })
                        .then(response => ({
                            type: 'create',
                            id: response.data.setting_id,
                            name,
                            value,
                            response
                        }));
                }
            });

            const results = await Promise.all(promises);

            const failures = results.filter(result => result.response.status !== 200 && result.response.status !== 201);

            if (failures.length > 0) {
                return rejectWithValue('Failed to save one or more company settings.');
            }
            return results.map(({ type, id, name, value }) => ({ type, id, name, value }));

        } catch (error) {
            console.error('Error in saveCompanySetting:', error);
            return rejectWithValue(error.message || 'An error occurred while saving settings.');
        }
    }
);

const companySettingsSlice = createSlice({
    name: 'companySettings',
    initialState: {
        data: [],
        loading: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanySettings.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(fetchCompanySettings.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.data = action.payload;
            })
            .addCase(fetchCompanySettings.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.payload;
            })
            .addCase(saveCompanySetting.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(saveCompanySetting.fulfilled, (state, action) => {
                state.loading = 'idle';
                action.payload.forEach(({ type, ...setting }) => {
                    const index = state.data.findIndex(s => s.id === setting.id);
                    if (index !== -1) {
                        // Update existing setting
                        state.data[index] = {
                            ...state.data[index],
                            ...setting
                        };
                    } else {
                        // Add new setting
                        state.data.push(setting);
                    }
                });
            })
            .addCase(saveCompanySetting.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.payload;
            });
    },
});

export const companySettingsSelector = (state) => state.companySettings.data;
export const loadingSelector = (state) => state.companySettings.loading;
export const errorSelector = (state) => state.companySettings.error;

export default companySettingsSlice.reducer;
