import { createSlice } from "@reduxjs/toolkit";

export const propertySearchSlice = createSlice({
  name: 'propertySearchValue',
  initialState: '',
  reducers: {
    setPropertyInputValue: (state, action) => {
      return action.payload;
    },
    clearPropertyInputValue: (state) => {
      return '';
    },
  },
});

export const { setPropertyInputValue, clearPropertyInputValue } = propertySearchSlice.actions;
export const propertySearchSelector = (state) => state.propertySearch;
export default propertySearchSlice.reducer;