import { createSlice } from "@reduxjs/toolkit";

export const flowSearchSlice = createSlice({
  name: 'flowSearchValue',
  initialState: '',
  reducers: {
    setInputValue: (state, action) => {
      return action.payload;
    },
    clearInputValue: (state) => {
      return '';
    },
  },
});

export const { setInputValue, clearInputValue } = flowSearchSlice.actions;
export const flowSearchSelector = (state) => state.flowSearch;
export default flowSearchSlice.reducer;