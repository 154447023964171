import { createSlice } from '@reduxjs/toolkit';

const checkboxSlice = createSlice({
    name: 'checkboxes',
    initialState: {
        selectedIds: [],
        isCheckAll: false,
        currentPage: 1,
    },
    reducers: {
        toggleCheckbox: (state, action) => {
            const id = action.payload;
            const index = state.selectedIds.indexOf(id);
            if (index > -1) {
                state.selectedIds.splice(index, 1);
            } else {
                state.selectedIds.push(id);
            }
        },
        setCheckAll: (state, action) => {
            state.isCheckAll = action.payload;
        },
        bulkToggleCheckboxes: (state, action) => {
            const { ids, isChecked } = action.payload;
            if (isChecked) {
                state.selectedIds = [...new Set([...state.selectedIds, ...ids])];
            } else {
                state.selectedIds = state.selectedIds.filter(id => !ids.includes(id));
            }
        },
        clearCheckboxes: (state) => {
            state.selectedIds = [];
            state.isCheckAll = false;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
});

export const {
    toggleCheckbox,
    setCheckAll,
    bulkToggleCheckboxes,
    clearCheckboxes,
    setCurrentPage
} = checkboxSlice.actions;

export default checkboxSlice.reducer;