import React from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  FormErrorMessage,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { showSuccessToast } from "screens/utils/toastUtil";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { api } from "api/apiEndPoints";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
// import illustration from "assets/img/auth/auth.png";
import illustration from "assets/img/auth/authBackground.png";
// import { FcGoogle } from "react-icons/fc";
// import { MdOutlineRemoveRedEye } from "react-icons/md";
// import { RiEyeCloseLine } from "react-icons/ri";
import { Field, Form, Formik } from "formik";
// import { FaShieldHeart } from "react-icons/fa6";
import * as Yup from "yup";
import {
  // authSelector,
  loadingSelector,
} from "redux/features/authentication/authSlice";
import { useSelector } from "react-redux";
// import { useState, useEffect } from "react";

function ResetPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("secondaryGreen.200", "white");
  const path = useLocation();
  const history = useHistory();
  const brandStars = useColorModeValue("secondaryGreen.200", "brand.400");
  // const [show, setShow] = React.useState(false);
  // const [showError, setShowError] = useState(false);
  const loading = useSelector(loadingSelector);
  // const auth = useSelector(authSelector);
  // const handleClick = () => setShow(!show);

  // useEffect(() => {
  //   if (auth?.status === 401) {
  //     setShowError(true);
  //   } else {
  //     setShowError(false);
  //   }
  // }, [auth]);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const handleResetPassword = async (values, actions) => {
    const body = {
      password: values.password,
    };
    try {
      const accessToken = path?.search.split("=")[1];
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.post(api().resetPassword, body, config);
      if (response.status === 200) {
        showSuccessToast("Password successfully reset!");
        history.replace("auth/default");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "50px" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="50px">
            Reset Password
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Formik
            initialValues={{
              password: "",
            }}
            onSubmit={(values, actions) => {
              handleResetPassword(values, actions);
            }}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        htmlFor="password"
                        color={textColor}
                        display="flex"
                      >
                        Password<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                      <Input
                        isRequired={true}
                        {...field}
                        id="password"
                        name="password"
                        borderColor={
                          form.errors.password &&
                          form.touched.password &&
                          "red.500"
                        }
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="password"
                        placeholder="Enter new password"
                        mb="24px"
                        fontWeight="500"
                        size="lg"
                      />
                    </FormControl>
                  )}
                </Field>

                <Button
                  fontSize="sm"
                  color="white"
                  type="submit"
                  backgroundColor="secondaryGreen.200"
                  fontWeight="500"
                  isLoading={loading === "loading"}
                  w="100%"
                  _hover={{ bg: "secondaryGreen.100" }}
                  _active={{ bg: "secondaryGreen.100" }}
                  h="50"
                  mb="24px"
                >
                  Confirm
                </Button>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
